@font-face {
  font-family: "Alpha-Regular";
  src: url("./fonts/Alpha-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Alpha-Regular";
  font-weight: bold;
  src: url("./fonts/Alpha-Bold.otf") format("opentype");
}

body {
  margin: 0;
  padding: 0;
}

.svg-hover:hover {
  cursor: pointer;
}

a {
  color: black;
}
